import React, { createContext, useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom';

export const CheckoutContext = createContext();

const CheckoutProvider = withRouter(({ location, children }) => {
    //const [ invoiceData, setInvoiceData ] = useState({});
    //const [ paymentData, setPaymentData ] = useState({});
    const [ checkoutFinishState, setCheckoutFinishState ] = useState(null);

    const resetCheckoutFinishState = useCallback(
        () => {
            setCheckoutFinishState(null);
        },
        [],
    );

    return (
        <CheckoutContext.Provider
            value={{
                /*invoiceData,
                setInvoiceData,
                paymentData,
                setPaymentData,*/
                checkoutFinishState,
                setCheckoutFinishState,
                resetCheckoutFinishState,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    )
});

export default CheckoutProvider;