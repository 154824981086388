const locale = require('../helpers/Locale');

const CustomPathDetector = {
    name: 'customPath',
  
    lookup(options) {
        const lookupFromPathIndex = 0;

        if (typeof window !== 'undefined') {
            const language = window.location.pathname.match(/\/([a-zA-Z-]{2}-[a-zA-Z-]{2})/g);
            if (language instanceof Array) {

                if (typeof language[lookupFromPathIndex] !== 'string') {
                    return undefined;
                }
                
                const detected = language[lookupFromPathIndex];
                const lang = detected.substr(1,2);
                const country = detected.substr(4);

                const detectedFormatted = lang.toLowerCase() + '-' + country.toUpperCase();
                //console.log("Detected formatted: ", detectedFormatted);

                if ( locale.isValidLocale( detectedFormatted ) ) {
                    return detectedFormatted;
                }
            }
        }
        return undefined;
    }
};

export default CustomPathDetector;