import React from 'react'
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

import Footer from "../Layout/Footer";

const GenericNotFound = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Container style={{ height: "60vh" }}>
                <Helmet>
                    <meta name="prerender-status-code" content="404" />
                </Helmet>

                <br />
                <br />
                <h1 style={{ color: "white" }}>{t("terms.pageNotFound")}</h1>
            </Container>

            <Footer />
        </div>
    );
}
 
export default GenericNotFound;
