import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

import LocalizedLink from "../Layout/LocalizedLink";

import { formatMoney } from "../../helpers/Currency";

const ShopDocument = ({ id, name, urlName, price, currency, disabled }) => {
    const { t } = useTranslation();

    let className = "medium_size_card";
    if (disabled) {
        className += " bg-dark";
    }

    let formatttedPrice = formatMoney(price, 0, ",", ".");

    return (
        <LocalizedLink
            className="document_card_anchor"
            to={"/templates/" + urlName}
            alt={name}
        >
            <Card fluid="true" className={className}>
                <Card.Body className="flex flex-row">
                    <div className="pb-2">{name}.</div>
                </Card.Body>
                <Card.Footer>
                    <div className="price">
                        {t("terms.currency" + currency + "Symbol")}
                        {formatttedPrice}
                        <small>{t("terms.currency" + currency + "Suffix")}</small>
                    </div>
                </Card.Footer>
            </Card>
        </LocalizedLink>
    );
};

export default ShopDocument;
