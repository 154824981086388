import React, { Fragment, useContext } from 'react';
import Container from 'react-bootstrap/Container';

import ShopUploadFile from './ShopUploadFile';
import ShopDocTypeCatalog from './ShopDocTypeCatalog';
import EmptyDocTypeCatalog from './EmptyDocTypeCatalog';
import LoadingDocTypeCatalog from './LoadingDocTypeCatalog';

import { TemplatesContext } from '../../context/TemplatesContext';

const ShopDocuments = ({ location }) => {
    const { templateDataLoading, templatesByCategory } = useContext(TemplatesContext);  
    const { pathname, hash } = location;

    let categorySearch;
    if ( pathname === '/' && hash ) {
        categorySearch = hash.substr(1, hash.length);
    }

    return (
        <Container fluid className="shop_documents doc_catalog p-0">
            <div id="shopDocuments" name="shopDocuments" className="shop_anchor"></div>
            { templateDataLoading 
                ? <LoadingDocTypeCatalog /> 
                : (
                    <Fragment>
                        { templatesByCategory.length 
                        ? <Fragment>
                            <ShopUploadFile categorySearch={categorySearch}/>
                            <ShopDocTypeCatalog categorySearch={categorySearch}/>
                        </Fragment>
                        : <EmptyDocTypeCatalog />
                        }
                    </Fragment>
                )
            }
        </Container>
    );
}
 
export default ShopDocuments;