import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";

import BannerHashTag from "./BannerHashTag";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <BannerHashTag />

            <Container fluid className="site_footer p-0">
                <Container className="footer_container p-lg-3">
                    <Row className="underlined">
                        <Col xs={6} className="company">
                            <a href={t("links.tocBiometrics")}>
                                <Image
                                    id="images.logoTocFooter"
                                    fluid
                                    src={t("images.files.logoTocFooter")}
                                    width="169"
                                    height="70"
                                    alt={t("images.alts.logoTocFooter")}
                                />
                            </a>
                        </Col>
                        <Col xs={6} className="siguenos">
                            <b style={{ marginRight: "0" }}>
                                {t("terms.followUsAt")}:<br />
                            </b>

                            <div style={{ margin: "0.6rem 0" }}>
                                <span style={{ whiteSpace: "nowrap" }}>
                                    <a href={t("links.youtube")}>
                                        <img
                                            id="images.iconoYoutube"
                                            width="34"
                                            height="34"
                                            src={t("images.files.iconoYoutube")}
                                            alt={t("images.alts.iconoYoutube")}
                                        />
                                    </a>
                                    <a href={t("links.fb")}>
                                        <img
                                            id="images.iconoFacebook"
                                            width="34"
                                            height="34"
                                            src={t("images.files.iconoFacebook")}
                                            alt={t("images.alts.iconoFacebook")}
                                        />
                                    </a>
                                </span>
                                <span style={{ whiteSpace: "nowrap" }}>
                                    <a href={t("links.linkedIn")}>
                                        <img
                                            id="images.iconoLinkedIn"
                                            width="34"
                                            height="34"
                                            src={t("images.files.iconoLinkedIn")}
                                            alt={t("images.alts.iconoLinkedIn")}
                                        />
                                    </a>
                                    <a href={t("links.instagram")}>
                                        <img
                                            id="images.iconoInstagram"
                                            width="34"
                                            height="34"
                                            src={t("images.files.iconoInstagram")}
                                            alt={t("images.alts.iconoInstagram")}
                                        />
                                    </a>
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="underlined poweredRow">
                        <Col xs={12} className="mdp p-0">
                            <Image
                                id="images.paymentMethods"
                                fluid
                                width="499"
                                height="43"
                                src={t("images.files.paymentMethods")}
                                alt={t("images.alts.paymentMethods")}
                            />
                        </Col>
                    </Row>

                    <Row className="links">
                        <Col xs={6} className="p-0">
                            <Row>
                                <Col md={6} className="link">
                                    <b>{t("terms.transparency")}:</b>
                                    <br />
                                    <br />
                                    <a href={t("links.privacy")}>
                                        {t("terms.politicsAndPrivacy")}
                                    </a>
                                    <br />
                                    <br />
                                    <a href={t("links.termsAndConditions")}>
                                        {t("terms.termsAndConditions")}
                                    </a>
                                    <br />
                                </Col>
                                <Col md={6} className="link">
                                    <b>{t("terms.contact")}:</b>
                                    <br />
                                    <br />
                                    <a
                                        href={
                                            "mailto: " + t("links.supportEmail")
                                        }
                                    >
                                        {t("links.supportEmail")}
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                        href={
                                            "mailto: " + t("links.contactEmail")
                                        }
                                    >
                                        {t("links.contactEmail")}
                                    </a>
                                    <br />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6} className="link">
                            <b>{t("terms.alliances")}:</b>
                            <br />

                            <span>
                                <img
                                    id="images.alliancesBiometricsInstitute"
                                    src={t("images.files.alliancesBiometricsInstitute")}
                                    alt={t("images.alts.alliancesBiometricsInstitute")}
                                />
                                <img
                                    id="images.alliancesEab"
                                    src={t("images.files.alliancesEab")}
                                    alt={t("images.alts.alliancesEab")}
                                />
                                <img
                                    id="images.alliancesIaLatam"
                                    src={t("images.files.alliancesIaLatam")}
                                    alt={t("images.alts.alliancesIaLatam")}
                                />
                            </span>
                        </Col>
                    </Row>
                </Container>

                <div className="bottom_page">
                    <a href={t("links.tocBiometrics")}>www.tocbiometrics.com</a>
                </div>
            </Container>
        </Fragment>
    );
};

export default Footer;
