const dev = {
    marketApi: {
        BASE_URL: "http://localhost:5000/api",
        VERSION: "v1"
    },
    mercadoPago: {
        TOKEN_CL: "TEST-a739d613-73a4-400e-b35b-621811df85b5",
        TOKEN_AR: "TEST-b8a47e57-e534-4cc5-ae3a-ce08f1dcf798",
    },
    analytics: {
        TOKEN: "UA-190054252-1", // TOC Pruebas
    },
    facebookPixel: {
        TOKEN: "",
    },
    gtm: {
        TOKEN: "",
        TOKEN_AR: "",
    },
    whatsApp: {
        URL: "https://api.whatsapp.com/send?phone=56964590311&text=Hola&lang=es",
    },
    vars: {
        UPLOAD_FILE_TEMPLATE_ID: 'SubirDocumento',
    },
    switches: {
        realPreviewEnabled: true,
        whatsappButtonEnabled: true,
    }
};

const beta = {
    marketApi: {
        BASE_URL: "https://beta.api.tocmarket.rebelsouls.io/api",
        VERSION: "v1"
    },
    mercadoPago: {
        TOKEN_CL: "APP_USR-39c6dd1c-ec16-414d-8528-9cc16cdd9d9e",
        //TOKEN_CL: "TEST-a739d613-73a4-400e-b35b-621811df85b5",
        TOKEN_AR: "APP_USR-f23afea9-d716-4a42-9919-23a7bbb16ab8",
        //TOKEN_AR: "TEST-b8a47e57-e534-4cc5-ae3a-ce08f1dcf798",
    },
    analytics: {
        TOKEN: "UA-190054252-1", // TOC Pruebas
    },
    facebookPixel: {
        TOKEN: "",
    },
    gtm: {
        TOKEN: "GTM-NPNBPQ6",
        TOKEN_AR: "GTM-54VJMZN",
    },
    whatsApp: {
        URL: "https://api.whatsapp.com/send?phone=56964590311&text=Hola&lang=es",
    },
    vars: {
        UPLOAD_FILE_TEMPLATE_ID: 'SubirDocumento',
    },
    switches: {
        realPreviewEnabled: true,
        whatsappButtonEnabled: false,
    }
};

const prod = {
    marketApi: {
        VERSION: "v1"
    },
    mercadoPago: {
        TOKEN_CL: "APP_USR-39c6dd1c-ec16-414d-8528-9cc16cdd9d9e",
        TOKEN_AR: "APP_USR-f23afea9-d716-4a42-9919-23a7bbb16ab8",
    },
    analytics: {
        TOKEN: "UA-187135814-1",
    },
    facebookPixel: {
        TOKEN: "798053751065018",
    },
    gtm: {
        TOKEN: "GTM-NPNBPQ6",
        TOKEN_AR: "GTM-54VJMZN",
    },
    whatsApp: {
        URL: "https://api.whatsapp.com/send?phone=56964590311&text=Hola&lang=es",
    },
    vars: {
        UPLOAD_FILE_TEMPLATE_ID: 'SubirDocumento',
    },
    switches: {
        realPreviewEnabled: true,
        whatsappButtonEnabled: false,
    }
};

let config;

switch (process.env.REACT_APP_STAGE) {
    case 'dev':
        config = dev;
        break;

    case 'beta':
        config = beta;
        break;

    default:
        config = prod;
}

const fullConfig = {
    // Agregar acá valores comunes a todos los ambientes
    ...config
};

export default fullConfig;
