import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";

import { SiteContext } from '../../context/SiteContext';

const ChatButton = () => {
    const { i18n } = useTranslation();
    const { env } = useContext(SiteContext);

    if (! env.switches.whatsappButtonEnabled || i18n.language !== "es-CL") {
        return null;
    }

    return (
        <div
            style={{ 
                //backgroundColor: "#fafafa", 
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                position: "fixed", 
                zIndex: "200", 
                bottom: "0.5rem", 
                right: "0"
            }}
        >
            <a
                href={env.whatsAppUrl}

            >
                <img
                    src="/img/whatsapp/logo80.png"
                    alt="WhatsApp"
                    width="80"
                    height="67"
                />
            </a>
        </div>
    );
}

export default ChatButton;