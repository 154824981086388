import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Cache from 'i18next-localstorage-cache';
import Backend from "i18next-http-backend";

import LanguageDetector from "i18next-browser-languagedetector";

import CustomPathDetector from "./i18n/CustomPathDetector";
import LinkPostProcessor from "./i18n/LinkPostProcessor";

import { getApiBaseUrlFromWindowLocation } from './helpers/TocUrls';

// detect user language
const languageDetector = new LanguageDetector();
languageDetector.addDetector(CustomPathDetector);

i18n.use(Backend)
    .use(Cache)
    .use(languageDetector)
    .use(new LinkPostProcessor())
    .use(initReactI18next)
    .init({
        detection: {
            order: ["customPath", "cookie"],
            lookupFromPathIndex: 0,
            caches: ["cookie"],
            //cookieDomain: 'myDomain',
            //cookieOptions: { path: '/', sameSite: 'strict' }
        },
        postProcess: ["linkPostProcessor"],
        fallbackLng: "es",
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            bindI18n: "languageChanged",
            bindI18nStore: "",
            transEmptyNodeValue: "",
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],
            useSuspense: true,
        },
        backend: {
            //loadPath: config.marketApi.BASE_URL + '/' + config.marketApi.VERSION + "/locales/{{lng}}/{{ns}}",
            loadPath: (lngs, namespaces) => (
                getApiBaseUrlFromWindowLocation() + "/locales/{{lng}}/{{ns}}"
            )
        },
    });

export default i18n;
