import React from 'react'
import Container from 'react-bootstrap/Container';

import LoadingBounces from '../Layout/LoadingBounces';

const LoadingDocTypeCatalog = () => {
    return (
        <Container className="text-center">
            <br />
            <br />
            <br />
            <LoadingBounces />
        </Container>
    );
}
 
export default LoadingDocTypeCatalog;