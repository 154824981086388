import React from 'react';
import { useTranslation } from 'react-i18next';

const PoweredBy = ({ variant }) => {
    const { t } = useTranslation();

    return (
        <div 
            className="poweredBy"
            style={{
                position: "absolute",
                right: "0",
                zIndex: "10",
            }}
        >
            <span>{t("terms.poweredBy")} </span>
            <a href="#">
                { variant && variant === 'light' 
                ? <img style={{ paddingLeft: "0.5rem", width: "195px" }} alt="LEGALSIGN" src="/img/store/logoLegalsignBlanco.png" />
                : <img style={{ paddingLeft: "0.5rem", width: "195px" }} alt="LEGALSIGN" src="/img/store/logoLegalsign.png" />
                }
            </a>            
        </div>
    );
}
 
export default PoweredBy;