import React, {
    useContext,
    createContext,
    useEffect,
    useState,
    useCallback,
} from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { SiteContext } from "./SiteContext";

import {
    getExtendedSchemasWithReferencesShortcut,
    getFieldListFromFormSchema,
} from "../helpers/TemplateSchemas";

export const TemplatesContext = createContext();
export const TemplateHTMLContext = createContext();

const TemplatesProvider = (props) => {
    // Información general de los templates y detalles de los formularios
    const [templateDataLoading, setTemplateDataLoading] = useState(true); // Asume que se carga al inicio
    const [templateDataFetchError, setTemplateDataFetchError] = useState(false);
    const [templatesByCategory, setTemplatesByCategory] = useState([]);
    const [templateFormSchemas, setTemplateFormSchemas] = useState({});

    // HTML de los templates
    const [templateId, setTemplateId] = useState();
    const [templateHTMLLoading, setTemplateHTMLLoading] = useState(false);
    const [templateHTMLFetchError, setTemplateHTMLFetchError] = useState(false);
    const [templateHTMLContents, setTemplateHTMLContents] = useState({});
    const [templateHTML, setTemplateHTML] = useState("");

    const { i18n } = useTranslation();
    const { getApiBaseUrl, setCsrfToken } = useContext(SiteContext);

    // La información de template se carga una sola vez al final del primer rendereo
    useEffect(() => {
        const fetchTemplatesByCategory = async () => {
            try {
                setTemplateDataLoading(true);
                setTemplateDataFetchError(false);

                const templatesResponse = await axios.get(
                    getApiBaseUrl() + "/templates/" + i18n.language,
                    {
                        withCredentials: true,
                    }
                );

                setTemplatesByCategory(
                    templatesResponse.data.templatesByCategory || []
                );

                const templateFormSchemasResponse =
                    templatesResponse.data.templateFormSchemas || {};
                setTemplateFormSchemas(
                    getExtendedSchemasWithReferencesShortcut(
                        templateFormSchemasResponse
                    )
                );

                setCsrfToken(templatesResponse.data.xsrf);
            } catch (e) {
                //console.error(e);
                setTemplateDataFetchError(true);

                setTemplatesByCategory([]);
                setTemplateFormSchemas({});
            } finally {
                setTemplateDataLoading(false);
            }
        };

        if (i18n.language) {
            fetchTemplatesByCategory();
        }
    }, [getApiBaseUrl, setCsrfToken, i18n.language]);

    const getOrFetchTemplateHTML = useCallback(
        async (templateId) => {
            if (templateId === undefined || templateId === null) return;

            if (!templateHTMLContents[templateId]) {
                try {
                    //console.log("Getting template HTML from the back-end for template:", templateId);

                    setTemplateHTMLLoading(true);
                    setTemplateHTMLFetchError(false);

                    const templatesResponse = await axios.get(
                        getApiBaseUrl() + "/templates/" + templateId + "/HTMLContent"
                    );

                    setTemplateHTMLContents((oldTemplateHTMLContents) => ({
                        ...oldTemplateHTMLContents,
                        [templateId]: templatesResponse.data,
                    }));
                } catch (e) {
                    //console.error(e);
                    setTemplateHTMLFetchError(true);
                } finally {
                    setTemplateHTMLLoading(false);
                }
            } else {
                //console.log("Getting template HTML from cache");
                setTemplateHTML(templateHTMLContents[templateId]);
            }
        },
        [getApiBaseUrl, templateHTMLContents]
    );

    // La información HTML del template cambia al cambiar el templateId con setTemplateId
    useEffect(() => {
        //console.log("TemplateId: ", templateId);

        if (templateId !== "SubirDocumento") {
            getOrFetchTemplateHTML(templateId);
        }
    }, [getOrFetchTemplateHTML, templateId]);

    const getTemplateDetails = useCallback(
        (templateId) => {
            if (templateId === undefined) return;

            const templateFormSchemaBase = templateFormSchemas[templateId]
                ? templateFormSchemas[templateId].formSchema
                : [];
            const templateFieldList = getFieldListFromFormSchema(
                templateFormSchemaBase
            );

            const template = {
                templateId: templateId,
                formSchema: templateFormSchemas[templateId] || {},
                fieldList: templateFieldList || [],
            };

            return template;
        },
        [templateFormSchemas]
    );

    const getTemplateInfoByTemplateUrlName = useCallback(
        (templateUrlName) => {
            for (let templateCategory of templatesByCategory) {
                for (let template of templateCategory.templates) {
                    // Me quedo con el primero que encuentro
                    if (template.templateUrlName === templateUrlName) {
                        //console.log("Found template: " + template.templateName);
                        return template;
                    }
                }
            }

            return undefined;
        },
        [templatesByCategory]
    );

    const getTemplateInfoById = useCallback(
        (templateId) => {
            for (let templateCategory of templatesByCategory) {
                for (let template of templateCategory.templates) {
                    // Me quedo con el primero que encuentro
                    if (template.templateId === templateId) {
                        //console.log("Found template: " + template.templateName);
                        return template;
                    }
                }
            }

            return undefined;
        },
        [templatesByCategory]
    );

    return (
        <TemplatesContext.Provider
            value={{
                templateDataLoading,
                templateDataFetchError,
                templatesByCategory,
                getTemplateDetails,
                getTemplateInfoByTemplateUrlName,
                getTemplateInfoById,
            }}
        >
            <TemplateHTMLContext.Provider
                value={{
                    setTemplateId,
                    templateId,
                    templateHTML,
                    templateHTMLLoading,
                    templateHTMLFetchError,
                }}
            >
                {props.children}
            </TemplateHTMLContext.Provider>
        </TemplatesContext.Provider>
    );
};

export default TemplatesProvider;
