import React from "react";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

const EmptyDocTypeCatalog = () => {
    const { t } = useTranslation();

    return (
        <Container className="text-center">
            <br />
            <p>{t("shop.noDocumentsAvailable")}</p>
        </Container>
    );
};

export default EmptyDocTypeCatalog;
