import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Tutorial = () => {
    const { i18n, t } = useTranslation();
    const history = useHistory();

    const tutorialSteps = [];
    let step = 1;
    let tutorialStep = t("tutorial.step" + step);
    while (tutorialStep && tutorialStep !== "tutorial.step" + step) {
        tutorialSteps.push({
            number: step,
            text: t("tutorial.step" + step),
            img: t("images.files.tutorialStep" + step),
            imgId: "images.tutorialStep" + step,
            imgAlt: t("images.alts.tutorialStep" + step),
        });

        step++;
        tutorialStep = t("tutorial.step" + step);
    }

    return (
        <Container className="tutorial">
            <h2>
                <b>{t("terms.forBuy")}</b> {t("terms.justFollow")}
                <br />
                <b>
                    {step - 1} {t("terms.easySteps")}:
                </b>
            </h2>

            <Row>
                {tutorialSteps.map((step) => (
                    <Col xl>
                        <h3>{step.number}.</h3>
                        <div
                            style={{ height: "85px" }}
                            className="w-100 d-flex align-bottom text-center"
                        >
                            <img
                                id={step.imgId}
                                className="m-auto"
                                src={step.img}
                                alt={step.imgAlt}
                            />
                        </div>
                        <p>{step.text}</p>
                    </Col>
                ))}
            </Row>

            <Row className="justify-content-center mt-5">
                <Col xl={4}>
                    <Button
                        block
                        variant="success"
                        onClick={() =>
                            history.push("/" + i18n.language + "/help")
                        }
                    >
                        {t("tutorial.btnHelpCenter")}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Tutorial;
