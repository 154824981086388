
export const getReferencesShortcutFromSchemasArray = ( schemasArray ) => {
    //console.log("Getting refeferences shortcuts from: ", schemasArray);
    let references = {};

    for ( let item of schemasArray ) {
        if ( item.fields ) {
            const childFields = getReferencesShortcutFromSchemasArray( item.fields );
            references = {
                ...references,
                ...childFields
            };
        }
        else {
            if ( item.type && item.type === 'reference' ) {
                references[item.references] = references[item.references] || [];
                references[item.references].push(item.name);
            }
        }
    }
    return references;
}

export const getExtendedSchemasWithReferencesShortcut = ( formSchemasResponse ) => {
    let extendedFormSchemas = {};
    const docs = Object.keys(formSchemasResponse);

    for ( let doc of docs ) {
        extendedFormSchemas[doc] = {
            "baseSchema": formSchemasResponse[doc],
            "references": getReferencesShortcutFromSchemasArray(formSchemasResponse[doc]),
        }
    }

    return extendedFormSchemas;
}

export const getFieldListFromFormSchema = ( formSchema ) => {
    if (! formSchema ) return null;

    let fieldList = [];
    for ( let sectionSchema of formSchema ) {
        fieldList.push( ...getFieldListFromGroupOrSectionSchema( sectionSchema ));
    }

    return fieldList;
}

export const getFieldListFromGroupOrSectionSchema = ( formGroupOrSectionSchema ) => {
    let fieldList = [];

    for ( let field of formGroupOrSectionSchema.fields ) {
        if ( field.fields ) {
            fieldList.push( ...getFieldListFromGroupOrSectionSchema( field ));
        }
        else {
            fieldList.push({
                name: field.name,
                label: field.label,
                type: field.type,
                size: field.size,
                references: field.references,
                default: field.default || '',
            });
        }
    }

    return fieldList;
}