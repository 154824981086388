import React, { Fragment, useContext }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

import ShopDocument from './ShopDocument';

import { SiteContext } from '../../context/SiteContext';
import { TemplatesContext } from '../../context/TemplatesContext';

const ShopUploadFile = () => {
    const { t } = useTranslation();

    const { site } = useContext(SiteContext);
    const { templateDataLoading, templatesByCategory } = useContext(TemplatesContext);

    // Asume que la primera categoría es la de upload
    const categoryInfo = templatesByCategory[0];

    let categoryName;
    let categoryTemplates = [];
    if ( categoryInfo ) {
        categoryName = "uploadFile";
        categoryTemplates = categoryInfo.templates;
    }

    const anchorId = "uploadFile";
    const template = categoryTemplates[0];

    return (
        <Fragment>
            { !templateDataLoading && categoryTemplates.length > 0
            ? <Container fluid className="doc_catalog_u">
                <div id={anchorId} name={categoryName} className="category_anchor"></div>

                <Container className="p-0">
                    <Row>
                        <Col lg={7}>
                            <Container className="shop_section_title">
                                <h1>
                                    <b>
                                        {t("shop.uploadFile")}
                                    </b>
                                    <br /> 
                                    {t("shop.andSendItToSign")}
                                </h1>
                            </Container>
                        </Col>
                        <Col lg={5}>
                            <Container>
                                <ShopDocument 
                                    site={site}
                                    id={template.templateId}
                                    name={t("documents.shopTitles." + template.templateId)}
                                    urlName={template.templateUrlName}
                                    price={template.templatePrice}
                                    currency={template.templatePriceCurrency}
                                    disabled={template.disabled}
                                />
                            </Container>
                        </Col>
                    </Row>
                        
                    <Row className="p-0">
                        <Container>
                            <br />
                            <br />
                            <div className="title_underline"></div>
                        </Container>
                    </Row>
                </Container>
            </Container>
            : null
            }
        </Fragment>
    );
}
 
export default ShopUploadFile;