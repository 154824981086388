import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import LocalizedContent from "./components/Layout/LocalizedContent";
import TrackedContent from "./components/Layout/TrackedContent";
import Header from "./components/Layout/Header";
import AnimatedContent from "./components/Layout/AnimatedContent";

import SiteProvider from "./context/SiteContext";
import ShoppingCartProvider from "./context/ShoppingCartContext";
import CheckoutProvider from "./context/CheckoutContext";
import TemplatesProvider from "./context/TemplatesContext";
import TemplateFormDataProvider from "./context/TemplateFormDataContext";

import "./App.scss";

function App() {
    return (
        <LocalizedContent>
            <SiteProvider>
                <Router>
                    <ShoppingCartProvider>
                        <CheckoutProvider>
                            <TemplatesProvider>
                                <TemplateFormDataProvider>
                                    <TrackedContent>
                                        <Header />
                                        <AnimatedContent />
                                    </TrackedContent>
                                </TemplateFormDataProvider>
                            </TemplatesProvider>
                        </CheckoutProvider>
                    </ShoppingCartProvider>
                </Router>
            </SiteProvider>
        </LocalizedContent>
    );
}

export default App;
