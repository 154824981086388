import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const PageMetadata = memo(({ pageId }) => {
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    
    if (!pageId) return null;

    const titleTerm = `metas.titles.${pageId}`;
    const descriptionTerm = `metas.descriptions.${pageId}`;
    const keywordsTerm = `metas.keywords.${pageId}`;

    const title = t(titleTerm);
    const description = t(descriptionTerm);
    const keywords = t(keywordsTerm);

    return (
        <Helmet htmlAttributes={{ lang: lang ? lang.substr(0, 2) : "es" }}>
            {title !== titleTerm
            ? <title>{title}</title>
            : <title>{t("metas.titles.default")}</title>
            }
            
            {description !== descriptionTerm ? (
                <meta name="description" content={description} />
            ) : null}

            {keywords !== keywordsTerm ? (
                <meta name="keywords" content={keywords} />
            ) : null}
        </Helmet>
    );
}, (prevProps, nextProps) => (prevProps.pageId === nextProps.pageId));

export default PageMetadata;
