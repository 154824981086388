import React, { useEffect } from 'react'

const LoadingBounces = () => {
    useEffect(() => {
        window.scrollTo(0,0);
      }, []);

    return (
        <div className="bouncing_spinner">
            <br />
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    );
}
 
export default LoadingBounces;