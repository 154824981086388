import React from "react";

import Welcome from "./Welcome";
import ShopDocuments from "./ShopDocuments";
import ScrollToTop from "../Layout/ScrollToTop";
import Footer from "../Layout/Footer";

const Shop = ({ location }) => {
    return (
        <div className="shop">
            <ScrollToTop />
            
            <Welcome />
            <ShopDocuments location={location} />

            <Footer />
        </div>
    );
};

export default Shop;
