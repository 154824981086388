exports.getPathnameWithoutLocale = ( pathname ) => {
    const pathnameWithoutLocale = pathname.replace(/^\/..-../, "");
    return pathnameWithoutLocale;
}

exports.getPageIdFromPathname = (pathname) => {
    const pathClean = this.getPathnameWithoutLocale(pathname) || "/";
    if (pathClean) {
        return pathClean.substring(1).toLowerCase();
    }

    return "notfound";
}