import React, { createContext, useState, useCallback } from 'react'
import axios from "axios";

import config from '../config';

import { getApiBaseUrlFromWindowLocation } from '../helpers/TocUrls';

export const SiteContext = createContext();

const SiteProvider = (props) => {
    const defaultEnv = {
        marketApiBaseUrl: config.marketApi.BASE_URL + '/' + config.marketApi.VERSION,
        mpTokenCl: config.mercadoPago.TOKEN_CL,
        mpTokenAr: config.mercadoPago.TOKEN_AR,
        analyticsToken: config.analytics.TOKEN,
        facebookPixelToken: config.facebookPixel.TOKEN,
        gtmToken: config.gtm.TOKEN,
        gtmTokenAr: config.gtm.TOKEN_AR,
        whatsAppUrl: config.whatsApp.URL,
        switches: config.switches || {},
        vars: config.vars || {},
    };

    const [ env, setEnv ] = useState(defaultEnv);
    const [ site, setSite ] = useState('personas');
    const [ csrfToken, setCsrfToken ] = useState();

    const getApiBaseUrl = useCallback(
        () => {
            return getApiBaseUrlFromWindowLocation();
        },
        [],
    );

    const revokeSession = useCallback(() => {
        window.location = "/";
    }, []);

    // Reload de la app en caso de servicios deprecados
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 410) {
                revokeSession();
            }
            return Promise.reject(error);
        }
    );

    return (
        <SiteContext.Provider
            value={{
                env,
                setEnv,
                site,
                setSite,
                csrfToken,
                setCsrfToken,
                getApiBaseUrl,
            }}
        >
            {props.children}
        </SiteContext.Provider>
    )
}

export default SiteProvider;