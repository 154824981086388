import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

import LoadingBounces from './LoadingBounces';
import Shop from '../Shop/Shop';
import GenericNotFound from '../Layout/GenericNotFound';
import { getLocales } from '../../helpers/Locale';

// Lazy loading
const DocBuilder = lazy(() => import('../DocBuilder/DocBuilder'));
const Checkout = lazy(() => import('../Checkout/Checkout'));
const PaymentReturn = lazy(() => import('../Checkout/PaymentReturn'));
const CheckoutFinish = lazy(() => import('../Checkout/CheckoutFinish'));
const Help = lazy(() => import('../Help/Help'));

const AnimatedContent = withRouter(({ location }) => {
    const { i18n } = useTranslation();

    const language = i18n.language;

    const locales = getLocales();
    const localePaths = locales.map(locale => "/" + locale);

    const targets = [
        { url: "/home", component: Shop },
        { url: "/checkout", component: Checkout },
        { url: "/checkout/paymentReturn", component: PaymentReturn },
        { url: "/checkout/finish", component: CheckoutFinish },
        { url: "/templates/:templateUrlName", component: DocBuilder },
        { url: "/help", component: Help },
    ];

    return (
        <Suspense fallback={<Container className="page_loading text-center" style={{paddingTop: "1.2rem"}}><LoadingBounces /></Container>}>
            <Container fluid className="site_content">
                <TransitionGroup>
                    <CSSTransition key={location.key} classNames="fade" timeout={0} unmountOnExit={true}>
                        <Switch location={location}>
                            {/* Soporte para antiguas URLs */}
                            <Redirect exact from="/" to={"/" + language + "/home"} />
                            <Redirect exact from={localePaths} to={"/" + language + "/home"} />

                            <Redirect exact from="/es/home" to="/es-CL/home" />

                            <Route 
                                exact 
                                path="/templates/:templateUrlName" 
                                render={ (props) => <Redirect to={"/" + language + "/templates/" + props.match.params.templateUrlName} /> }
                            />

                            <Redirect exact from="/help" to={"/" + language + "/help"} />
                            
                            {/* Nuevas URLs por país */}
                            {locales.map(locale => 
                                targets.map((target, index) => 
                                    <Route key={"target-" + index} exact path={"/" + locale + target.url} component={target.component} />
                                )
                            )}

                            <Route component={GenericNotFound} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </Container>
        </Suspense>
    );
});

export default AnimatedContent;
