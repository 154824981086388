export const formatMoney = (n, c, d, t) => {
    let s, i, j;

    c = Math.abs(c);
    c = isNaN(c) ? 2 : c;
    d = d == undefined ? "." : d;
    t = t == undefined ? "," : t;
    s = n < 0 ? "-" : "";
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
    j = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}
