import React, { createContext, useState, useCallback } from "react";

export const TemplateFormDataContext = createContext();

const TemplateFormDataProvider = (props) => {
    // Esto se va llenando según los usuarios completen formularios
    const [templateFormData, setTemplateFormData] = useState({});
    const [templateForm, setTemplateFormDetails] = useState({});

    const getTemplateFormData = useCallback(
        (templateId) => {
            if (!templateId) return null;

            const fieldData = templateFormData[templateId] || {};
            return fieldData;
        },
        [templateFormData]
    );

    const updateTemplateFormData = useCallback(
        (templateId, updatedFields) => {
            setTemplateFormData(oldTemplateFormData => {
                if (!templateId) return oldTemplateFormData;
    
                const fieldData = oldTemplateFormData[templateId] || {};
                return {
                    ...oldTemplateFormData,
                    [templateId]: {
                        ...fieldData,
                        ...updatedFields,
                    },
                };
            });
        },
        []
    );

    const setTemplateForm = useCallback((templateId, references) => {
        setTemplateFormDetails({
            templateId: templateId,
            formReferences: references,
        });
    }, []);

    const handleFieldChange = useCallback(
        (fieldName, fieldValue) => {
            const templateId = templateForm.templateId;
            if (!templateId) return;

            const inputValue =
                typeof fieldValue === "string"
                    ? fieldValue.toUpperCase()
                    : fieldValue;
            const formReferences = templateForm
                ? templateForm.formReferences
                : null;

            let updatedFields = {};
            updatedFields[fieldName] = inputValue;

            if (formReferences && formReferences[fieldName]) {
                for (let reference of formReferences[fieldName]) {
                    updatedFields[reference] = inputValue;
                }
            }
            //console.log("updated fields: ", updatedFields);
            updateTemplateFormData(templateId, updatedFields);
        },
        [templateForm, updateTemplateFormData]
    );

    return (
        <TemplateFormDataContext.Provider
            value={{
                templateForm,
                setTemplateForm,
                templateFormData,
                setTemplateFormData,
                updateTemplateFormData,
                getTemplateFormData,
                handleFieldChange,
            }}
        >
            {props.children}
        </TemplateFormDataContext.Provider>
    );
};

export default TemplateFormDataProvider;
