import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { CSSTransition } from "react-transition-group";
import { useTranslation } from 'react-i18next';

import { getTocStoreHomeUrl } from '../../helpers/TocUrls';

const RegionBar = ( { showRegionBar, setShowRegionBar } ) => {
    const { t, i18n } = useTranslation();

    const changeLanguage = ( lng ) => {
        //console.log("Changing language to: ", lng);

        i18n.changeLanguage(lng);
        setShowRegionBar(false);

        window.location = getTocStoreHomeUrl(lng);
    };

    return (
        <CSSTransition 
            in={showRegionBar} 
            timeout={50} 
            classNames="regionBar"
            unmountOnExit
        >
            <Alert className="regionBar" variant="dark" onClose={() => setShowRegionBar(false)} dismissible>
                <Container>
                    <Row className="w-100">
                        <Col sm={9} className="text-right" style={{ marginTop: "0.5rem" }}>
                            <span>
                                {t('regionBar.msg')}
                            </span>
                        </Col>

                        <Col sm={3} className="text-left">
                            <Form inline className="">
                                <Form.Control
                                    as="select"
                                    className="mr-sm-2"
                                    custom
                                    onChange={ (event) => changeLanguage(event.target.value) }
                                    defaultValue={ i18n.language }
                                >
                                    <option value="es-CL">Chile</option>
                                    <option value="es-AR">Argentina</option>
                                </Form.Control>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Alert>

        </CSSTransition>
    );
}
 
export default RegionBar;