import React from 'react';
import Modal from 'react-bootstrap/Modal';

import Tutorial from './Tutorial';

const ModalTutorial = ({ modalShow, setModalShow }) => {
    return (
        <Modal 
            className="tutorial_modal"
            show={modalShow}
            size="xl"
            centered
            onHide={() => setModalShow(false)}
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <Tutorial />
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}
 
export default ModalTutorial;