import React, { Fragment, useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SiteContext } from '../../context/SiteContext';

const TrackedContent = withRouter(({ location, children }) => {
    const { i18n } = useTranslation();
    const { env } = useContext(SiteContext);

    useEffect(() => {
        // Analytics
        ReactGA.initialize(env.analyticsToken);//, { debug: true });

        // Facebook
        if (env.facebookPixelToken) {
            const options = {
                autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                debug: false, // enable logs
            };
            ReactPixel.init(env.facebookPixelToken, {}, options);
        }
    }, [env.analyticsToken, env.facebookPixelToken]);

    // Registro de pageviews en analytics
    useEffect(() => {
        ReactGA.pageview(location.pathname);

        if (env.facebookPixelToken) {
            ReactPixel.pageView();
        }

        // Gtm
        const gtmToken = i18n.language === "es-AR" ? env.gtmTokenAr : env.gtmToken;
        if (gtmToken) {
            TagManager.initialize({
                gtmId: gtmToken,
            });
        }

    }, [env.facebookPixelToken, env.gtmToken, env.gtmTokenAr, i18n.language, location.pathname]);

    return (
        <Fragment>
            {children}
        </Fragment>
    );
})
 
export default TrackedContent;