import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LocalizedLink = ({ to, children, className, alt }) => {
    const { i18n } = useTranslation();

    const localizedTo = '/' + i18n.language + to;

    return (
        <Link to={localizedTo} className={className} alt={alt}>
                {children}
        </Link>
    );
}
 
export default LocalizedLink;