import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';

const BannerHashTag = () => {
    const { t } = useTranslation();

    return (
        <Container fluid className="banner_hashtag p-0">
            <Container className="text-center">
                <Image 
                    id="images.hashTagDoItWithTOC"
                    className="m-auto w-100" 
                    fluid 
                    src={t("images.files.hashTagDoItWithTOC")} 
                    alt={t("images.alts.hashTagDoItWithTOC")} 
                />
            </Container>
        </Container>
    );
}
 
export default BannerHashTag;