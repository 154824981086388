import React, { useContext } from 'react'

import Col from 'react-bootstrap/Col';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';

const ShopDocTypeTitle = ({ imageUrl, categoryLabel, eventKey, callback }) => {

    const currentEventKey = useContext(AccordionContext);
          
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );
    
    const arrowClassName = (currentEventKey === eventKey)
        ? 'arrow up'
        : 'arrow down';

    const underlineClassName = "title_underline";

    return ( 
        <Col onClick={decoratedOnClick} className="p-0">
            <h2>                    
                {categoryLabel}
                <span className="indicator_arrow">
                    <i className={arrowClassName}></i>
                </span>
            </h2>
            <br />
            <div className={underlineClassName}></div>
        </Col>
    );
}
 
export default ShopDocTypeTitle;