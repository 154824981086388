import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

import ShopDocTypeTitle from './ShopDocTypeTitle';
import ShopDocument from './ShopDocument';

import { SiteContext } from '../../context/SiteContext';

const ShopDocCatalog = ({ categoryId, categoryName, categoryLabel, categoryImage, categoryTemplates }) => {
    const { t } = useTranslation();

    const { site } = useContext(SiteContext);

    const imageUrl = "/img/market/" + categoryImage;
    const anchorId = categoryId;

    if ( !categoryLabel ) {
        categoryLabel = <span>{t("terms.sign")}&nbsp;{categoryName}</span>;
    }

    return (
        <Container>
            <div id={anchorId} name={categoryName} className="category_anchor"></div>
            <Accordion defaultActiveKey={categoryId}>

                <ShopDocTypeTitle 
                    eventKey={categoryId} 
                    site={site}
                    imageUrl={imageUrl}
                    categoryLabel={categoryLabel}
                />
    
                <Accordion.Collapse eventKey={categoryId}>
                    <Row>
                    {categoryTemplates.map( template => (
                        <Col md={4} key={template.templateId} style={{ paddingBottom: "2rem" }}>
                             <ShopDocument 
                                site={site}
                                id={template.templateId}
                                name={t("documents.shopTitles." + template.templateId)}
                                urlName={template.templateUrlName}
                                price={template.templatePrice}
                                currency={template.templatePriceCurrency}
                                disabled={template.disabled}
                            />
                        </Col>
                    ))}
                    </Row>
                </Accordion.Collapse>
            </Accordion>
        </Container>
    )
}

export default ShopDocCatalog;