import React, { createContext, useState } from 'react'

export const ShoppingCartContext = createContext();

const ShoppingCartProvider = (props) => {
    const [ shoppingCart, setShoppingCart ] = useState([]);

    return (
        <ShoppingCartContext.Provider
            value={{
                shoppingCart,
                setShoppingCart
            }}
        >
            {props.children}
        </ShoppingCartContext.Provider>
    )
}

export default ShoppingCartProvider;