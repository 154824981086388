const appConfig = require("../config").default;
const locale = require("./Locale");

exports.getTocWebHomeUrl = (language) => {
    return (
        "https://www." +
        this.getDomainForLanguage(language) +
        this.getHomePathForLanguage(language)
    );
};

exports.getTocStoreHomeUrl = (language) => {
    return (
        "https://store." +
        this.getDomainForLanguage(language) +
        this.getHomePathForLanguage(language)
    );
};

exports.getHomePathForLanguage = (language) => {
    return "/" + language + "/home";
};

exports.getDomainForLanguage = (language) => {
    if (language === "es-AR") {
        return "tocbiometrics.com.ar";
    } else {
        return "tocbiometrics.com";
    }
};

exports.getLocalizedWebUrlForLanguage = (relativePath, language) => {
    return (
        "https://www." +
        this.getDomainForLanguage(language) +
        "/" +
        language +
        relativePath
    );
};

exports.getLocalizedStoreUrlForLanguage = (relativePath, language) => {
    return (
        "https://store." +
        this.getDomainForLanguage(language) +
        "/" +
        language +
        relativePath
    );
};

exports.getApiBaseUrlForLanguage = (language) => {
    if (appConfig.marketApi.BASE_URL) {
        return appConfig.marketApi.BASE_URL + '/' + appConfig.marketApi.VERSION;
    } else {
        return (
            "https://api.store." +
            this.getDomainForLanguage(language) +
            "/api/" +
            appConfig.marketApi.VERSION
        );
    }
};

exports.getApiBaseUrlFromWindowLocation = () => {
    let languageDetected = "es-CL";

    const lookupFromPathIndex = 0;
    if (typeof window !== "undefined") {
        const language = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
        if (language instanceof Array) {
            if (typeof language[lookupFromPathIndex] === "string") {
                const detected = language[lookupFromPathIndex].replace("/", "");
                //console.log("Detected language from location: ", detected);

                if (locale.isValidLocale(detected)) {
                    languageDetected = detected;
                }
            }
        }
    }

    const apiBaseUrl = this.getApiBaseUrlForLanguage(languageDetected);
    //console.log("Api base url from location: ", apiBaseUrl);

    return apiBaseUrl;
};
