import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { FaQuestionCircle } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import { HashLink as Link } from 'react-router-hash-link';

import ModalTutorial from "./ModalTutorial";
import PoweredBy from "../Layout/PoweredBy";
import useWindowDimensions from "../../hooks/WindowDimensions";

import "./Welcome.scss";

const Welcome = () => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const [tutorialShow, setTutorialShow] = useState(false);
    const [showImages, setShowImages] = useState(false);

    useEffect(() => {
        const enableImages = (width) => {
            if (width > 920) {
                setShowImages(true);
            }
        };

        enableImages(width);
    }, [width]);

    return (
        <Container fluid className="welcome" style={{ overflow: "hidden" }}>
            <Container style={{ position: "relative" }}>
                <PoweredBy />

                <Row>
                    {showImages
                    ? <Col lg={7}>
                        <picture id="images.welcome">
                            <source 
                                srcSet="/img/store/welcome3.webp" 
                                type="image/webp"
                                className="d-none d-lg-block img-fluid"
                                style={{
                                    position: "absolute",
                                    bottom: "-120px",
                                    left: "-140px",
                                }}
                                alt={t("images.alts.welcome")}
                            />
                            <img 
                                className="d-none d-lg-block img-fluid"
                                style={{
                                    position: "absolute",
                                    bottom: "-120px",
                                    left: "-140px",
                                }}
                                src="/img/store/welcome3C.png"
                                alt={t("images.alts.welcome")}
                            />
                        </picture>

                        <img
                            id="images.welcomeLogoStore"
                            style={{
                                position: "absolute",
                                bottom: "-54px",
                                left: "10px",
                            }}
                            className="d-none d-lg-block img-fluid"
                            src={t("images.files.welcomeLogoStore")}
                            alt={t("images.alts.welcomeLogoStore")}
                        />
                    </Col>
                    : null
                    }
                    <Col lg={5}>
                        <br />
                        <h1>
                            <b>{t("welcome.title")}</b>
                        </h1>
                        <br />
                        <p>{t("welcome.intro")}</p>
                        <p>
                            <b>{t("welcome.lead")}</b>
                        </p>
                        <div className="d-none d-xl-block">
                            <br />
                        </div>
                        <Row>
                            <Col xl={12}>
                                <Link to="#shopDocuments" style={{ textDecoration: "none" }}>
                                <Button
                                    variant="dark"
                                    size="lg"
                                    block
                                    style={{ marginTop: "1rem" }}
                                >
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        Compra aquí
                                        <FaCartArrowDown
                                            style={{
                                                fontSize: "1.2rem",
                                                marginLeft: "1rem",
                                                marginBottom: "4px",
                                            }}
                                        />
                                    </span>
                                </Button>
                                </Link>
                            </Col>
                            <Col xl={12}>
                                <Button
                                    variant="dark"
                                    size="lg"
                                    onClick={() => setTutorialShow(true)}
                                    block
                                    style={{ marginTop: "1rem" }}
                                >
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        {t("welcome.questionHowToBuy")}
                                        <FaQuestionCircle
                                            style={{
                                                fontSize: "1.2rem",
                                                marginLeft: "1rem",
                                                marginBottom: "4px",
                                            }}
                                        />
                                    </span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row></Row>

                <ModalTutorial
                    modalShow={tutorialShow}
                    setModalShow={setTutorialShow}
                />
            </Container>
        </Container>
    );
};

export default Welcome;
