import React, { Suspense } from 'react';

import LoadingBounces from './LoadingBounces';
import "../../i18n";

const LocalizedContent = ({ children }) => {

    return (
        <Suspense fallback={<LoadingBounces />}>
            {children}
        </Suspense>
    );
}
 
export default LocalizedContent;