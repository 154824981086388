import React, { Fragment, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

import ShopDocCatalog from './ShopDocCatalog';

import './Shop.scss';

import { TemplatesContext } from '../../context/TemplatesContext';

const ShopDocTypeCatalog = ({ categorySearch }) => {
    const { t } = useTranslation();

    const { templateDataLoading, templatesByCategory } = useContext(TemplatesContext);

    // Para que funcionen los anchors de categoria con el contenido dinámico
    useEffect( () => {
        if ( categorySearch && !templateDataLoading ) {
            const element = document.getElementById("anchor_" + categorySearch);
            if ( element ) {
                element.scrollIntoView();
            }
        }
    }, [categorySearch, templateDataLoading]);

    return (
        <Fragment>
            { !templateDataLoading && templatesByCategory.length > 1 ? (
            <Container fluid className="doc_catalog_c">
                <Container className="shop_section_title">
                    <h1>
                        <b>
                            {t("shop.customizeAndSign")}
                        </b><br />
                        {t("shop.theDocumentsYouNeed")}
                    </h1>
                </Container>
                
                { templatesByCategory.map( (categoryData, index) => {
                    if ( index > 0 && !categoryData.disabled ) { // Ahora asume que la primera categoría es el upload
                        return (
                            <Row 
                                className={ "doc_catalog_" + categoryData.categoryId }
                                key={categoryData.categoryId}
                            >
                                <ShopDocCatalog
                                    categoryId={categoryData.categoryId}
                                    categoryName={t("documents.shopCategories." + categoryData.categoryId)}
                                    categoryImage={categoryData.categoryImage}
                                    categoryTemplates={categoryData.templates}
                                />
                            </Row>
                        )
                    }
                    else {
                        return null;
                    }
                })}
            </Container>
            )
            : null
            }
        </Fragment>
    );
}

export default ShopDocTypeCatalog;