import { nanoid } from 'nanoid';

class LinkPostProcessor {
    constructor(opts = {}) {
        this.name = `linkPostProcessor`;
        this.type = `postProcessor`;
    }

    process(value, key, options) {
        const tokens = value.split(/(<link(?: [^>]+)?>.+?<\/link>)/);

        if (tokens.length === 1) {
            return tokens[0];
        }

        return tokens
            .filter(Boolean)
            .map((token) => {
                const vars = token.match(/<link( to=[^>]+)?>(.+?)<\/link>/);
                if (!vars) {
                    return token;
                }

                const args = vars[1] ? vars[1].split(/\s+/) : null;
                const content = vars[2];

                const parsedArgs = {};
                if (args) {
                    args.filter(Boolean).forEach((arg) => {
                        const parts = arg.split(/=/);
                        parsedArgs[parts[0]] = parts[1].replace(/[\\"]/g, "");
                    });
                }

                return <a 
                    className="poLink"
                    key={nanoid()}
                    href={parsedArgs.to ? parsedArgs.to : content}>
                    {content}
                </a>;
            });
    }
}

export default LinkPostProcessor;
