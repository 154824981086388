import React, { Fragment, useContext, useEffect, useState } from 'react';
//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
//import NavItem from 'react-bootstrap/NavItem';
//import NavLink from 'react-bootstrap/NavLink';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { SiteContext } from '../../context/SiteContext';

import RegionBar from './RegionBar';
import ChatButton from './ChatButton';
import PageMetadata from "./PageMetadata";
import LocalizedLink from "./LocalizedLink";

import { getPageIdFromPathname } from '../../helpers/Paths';
import { getTocWebHomeUrl } from '../../helpers/TocUrls';

const Header = withRouter(({location}) => {
    const { i18n, t } = useTranslation();
    const lang = i18n.language;

    const [showRegionBar, setShowRegionBar] = useState(false);
    const [pageId, setPageId] = useState(getPageIdFromPathname(location.pathname));

    const { site, setSite } = useContext(SiteContext);

    useEffect(() => {
        const setSiteFromLocation = (site, setSite, location) => {
            //console.log("site, location: ", site, location);

            if (location.pathname.match(/^\/empresas/) && site !== "empresas") {
                setSite("empresas");
            }

            if (!location.pathname.match(/^\/empresas/) && site !== "personas") {
                setSite("personas");
            }


        }

        setSiteFromLocation(site, setSite, location);
    }, [site, setSite, location]);

    // Fix para páginas que aparecen con la mitad del scroll
    useEffect(() => {
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual"
        }
    }, []);

    // Fix para cuando no hay GeoIP
    useEffect(() => {
        if (lang === "es") {
            window.location = "/es-CL/home";
        }
    }, [lang]);

    useEffect(() => {
        const updatePageId = (pathname) => {
            setPageId(getPageIdFromPathname(pathname));
        }

        updatePageId(location.pathname);
    }, [location.pathname]);

    return (
        <Fragment>
            <PageMetadata pageId={pageId} />
            <Navbar className="p-0" expand="lg" variant="dark" bg="dark" fixed="top" collapseOnSelect="true">
                <RegionBar 
                    showRegionBar={showRegionBar}
                    setShowRegionBar={setShowRegionBar}
                />


            <div className="w-100 d-flex align-middle" style={{minHeight: "77px", padding: "0.5rem 1rem"}}>
                <Container className="p-0">

                    <Navbar.Brand as={Link} to={site === "empresas" ? "/empresas" : "/"}>
                        <img
                            src="/img/store/navLogoTOCSTORE.png"
                            className="d-inline-block align-top"
                            alt="TOC MARKET"
                        />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="toc-navbar-nav" />

                    <Navbar.Collapse id="toc-navbar-nav">

                        <Nav className="mr-auto">
                            {/*<NavItem>
                            <NavLink onClick={ (event) => gotoHandler(event, "personas") } eventKey={1} id="personas-nav" active>Firma documentos</NavLink>
                        </NavItem>*/}
                        </Nav>

                        { lang
                        ? <Button 
                            className="pr-4 linkLang"
                            variant="link"
                            onClick={ () => setShowRegionBar(true) }
                        >   
                            <img 
                                src={t('currentCountry.flag')} 
                                alt={t('currentCountry.name')} 
                                width="26" 
                                height="26" 
                                style={{ marginRight: "0.5rem" }}
                            />

                            <Navbar.Text >
                                    {t('currentCountry.name')}
                            </Navbar.Text>  
                        </Button>
                        : null
                        }

                        <a href={getTocWebHomeUrl(lang)}>
                            <Button className="pr-4 boton_ayuda" variant="link">
                                <img
                                    src="/img/store/iconoWeb.png"
                                    width="28"
                                    height="28"
                                    alt=""
                                    style={{ marginRight: "0.5rem" }}
                                />
                                <Navbar.Text>
                                    {t("terms.corporateSite")}
                                </Navbar.Text>
                            </Button>
                        </a>

                        <LocalizedLink to="/help">
                            <Button className="boton_ayuda" variant="link">
                                <img
                                    src="/img/store/iconoAyuda.png"
                                    width="28"
                                    height="28"
                                    alt=""
                                    style={{ marginRight: "0.5rem" }}
                                />
                                <Navbar.Text>
                                    {t("terms.helpCenter")}
                                </Navbar.Text>
                            </Button>
                        </LocalizedLink>
                    </Navbar.Collapse>

                </Container>
                </div>
            </Navbar>

            <ChatButton />

            <div className="navbar_spacer">
            </div>

        </Fragment>
    )
});

export default Header;