exports.getLocales = () => {

    const locales = [
        'es-CL',
        'es-AR',
    ];

    return locales;
}

exports.isValidLocale = ( locale ) => {
    let isValid = false;

    const validLocales = this.getLocales();
    validLocales.forEach(
        validLocale => {
            if ( locale === validLocale ) {
                isValid = true;
            }
        }
    )

    return isValid;
}